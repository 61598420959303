import React, { Component } from 'react'

class Home extends Component {
  render () {
    return (
      <section id='home' className='home'>
        <div className='bg-overlay' />
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-8 col-sm-12'>
              <div className='home-text sm-m-100px-t sm-m-30px-b'>
                <h6>au service des victimes de dommages corporels</h6>
                <h1>M<span className='eSpe'>e</span> Laura WITZ</h1>
                <h4 className='theme-bg'>
                    Avocat en droit de la santé au Barreau d'Ajaccio
                </h4>
              </div>
            </div>
            <div className='col-md-4 col-sm-0' />
          </div>
        </div>
      </section>
    )
  }
}

export default Home
