
import React, { Component } from 'react'
import Logo from '../assets/img/logo_f.png'
import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa'
import FiledOfWorkPopup from '../components/FiledOfWorkPopup'

class Footer extends Component {
  render () {
    return (
      <footer className='footer'>
        <div className='container'>
          <div className='footer-logo'>
            <img src={Logo} alt='' />
          </div>
          <ul className='social-icons'>
            <li><a href='https://www.facebook.com/LAURA-WITZ-Avocat-110847407463766' rel='noopener noreferrer' target='_blank'><FaFacebookF /></a></li>
            <li><a href='https://www.instagram.com/laurawitz_avocat/' rel='noopener noreferrer' target='_blank'><FaInstagram /></a></li>
            <li><a href='https://fr.linkedin.com/in/laura-witz-0492488b' rel='noopener noreferrer' target='_blank'><FaLinkedin /></a></li>
          </ul>
          <p className='copyright'>© 2020 Laura WITZ. All Rights Reserved - <a data-toggle='modal' data-target='#Mentions_Legales' href='#link'>Mentions légales</a></p>
          <FiledOfWorkPopup id='Mentions_Legales' title='Mentions Légales' />
        </div>
      </footer>
    )
  }
}

export default Footer
