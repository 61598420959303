import React, { Component } from 'react'
import Logo from '../assets/img/logo.png'
import LogoB from '../assets/img/logo_b.png'

class Header extends Component {
  render () {
    return (
      <header>
        <nav id='nav' className='navbar header-nav fixed-top navbar-expand-lg'>
          <div className='container'>
            <a className='navbar-brand' href='/'>
              <img id='logo' src={Logo} alt='' />
              <img id='logob' className='hide' src={LogoB} alt='' />
            </a>
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbar' aria-controls='navbar' aria-expanded='false' aria-label='Toggle navigation'>
              <span />
              <span />
              <span />
            </button>
            <div id='navbar' className='collapse navbar-collapse justify-content-end'>
              <ul className='navbar-nav'>
                <li><a className='nav-link active' href='#home'>Accueil</a></li>
                <li><a className='nav-link' href='#Presentation'>Présentation</a></li>
                <li><a className='nav-link' href='#Domaines_activites'>Domaines d'activités</a></li>
                <li><a className='nav-link' href='#Honoraires'>Honoraires</a></li>
                <li><a className='nav-link' href='#Contact'>Contact</a></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
