import React, { Component } from 'react'
import { GiMedicalPackAlt, GiInjustice, GiScalpel } from 'react-icons/gi'
import { FaFileMedicalAlt, FaCarCrash } from 'react-icons/fa'
import { BiHandicap } from 'react-icons/bi'
import { TiSocialFlickr } from 'react-icons/ti'
import FiledOfWorkPopup from '../components/FiledOfWorkPopup'

class FieldsOfWork extends Component {
  render () {
    return (
      <section id='Domaines_activites' className='section'>
        <div className='container'>
          <div className='row justify-content-center text-center m-45px-b md-m-25px-b'>
            <div className='col-md-8 col-lg-6'>
              <div className='section-title'>
                <h6>Domaines d'activités</h6>
                <div className='btm-style'>
                  <span><TiSocialFlickr /></span>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>

            <div className='col-12 col-md-6 col-lg-4 m-15px-tb'>
              <div className='feature-box'>
                <div className='icon'>
                  <FaFileMedicalAlt />
                </div>
                <div className='feature-content'>
                  <h5>Droit médical</h5>
                  <p className='feature-content-desc'>
                    Responsabilité médicale, hospitalière<br />
                    Alea thérapeutique & Infection nosocomiale
                  </p>
                </div>
                <div className='feature-link'>
                  <button className='m-btn' type='button' data-toggle='modal' data-target='#Droit_medical' href='#link'><span>Plus d'infos</span></button>
                  <div className='clear' />
                </div>
              </div>
            </div>
            <FiledOfWorkPopup id='Droit_medical' title='Droit médical' />

            <div className='col-12 col-md-6 col-lg-4 m-15px-tb'>
              <div className='feature-box'>
                <div className='icon'>
                  <GiMedicalPackAlt />
                </div>
                <div className='feature-content'>
                  <h5>Droit pharmaceutique</h5>
                  <p className='feature-content-desc'>Préjudices causés par la prise d’un médicament</p>
                </div>
                <div className='feature-link'>
                  <button className='m-btn' type='button' data-toggle='modal' data-target='#Droit_pharmaceutique' href='#link'><span>Plus d'infos</span></button>
                  <div className='clear' />
                </div>
              </div>
            </div>
            <FiledOfWorkPopup id='Droit_pharmaceutique' title='Droit pharmaceutique' />

            <div className='col-12 col-md-6 col-lg-4 m-15px-tb'>
              <div className='feature-box'>
                <div className='icon'>
                  <GiScalpel />
                </div>
                <div className='feature-content'>
                  <h5>Produits défecteux</h5>
                  <p className='feature-content-desc'>Préjudices causés par l’utilisation d’un produit de santé défectueux</p>
                </div>
                <div className='feature-link'>
                  <button className='m-btn' type='button' data-toggle='modal' data-target='#Produits_defecteux' href='#link'><span>Plus d'infos</span></button>
                  <div className='clear' />
                </div>
              </div>
            </div>
            <FiledOfWorkPopup id='Produits_defecteux' title='Produits défecteux' />

            <div className='col-12 col-md-6 col-lg-4 m-15px-tb'>
              <div className='feature-box'>
                <div className='icon'>
                  <FaCarCrash />
                </div>
                <div className='feature-content'>
                  <h5>Accidents</h5>
                  <p className='feature-content-desc'>
                    Circulation, Travail<br />
                    Vie quotidienne & Sport
                  </p>
                </div>
                <div className='feature-link'>
                  <button className='m-btn' type='button' data-toggle='modal' data-target='#Accidents' href='#link'><span>Plus d'infos</span></button>
                  <div className='clear' />
                </div>
              </div>
            </div>
            <FiledOfWorkPopup id='Accidents' title='Accidents' />

            <div className='col-12 col-md-6 col-lg-4 m-15px-tb'>
              <div className='feature-box'>
                <div className='icon'>
                  <BiHandicap />
                </div>
                <div className='feature-content'>
                  <h5>Droit du handicap</h5>
                  <p className='feature-content-desc'>Conseils & procédures relatives aux droits des personnes en situation de handicap</p>
                </div>
                <div className='feature-link'>
                  <button className='m-btn' type='button' data-toggle='modal' data-target='#Droit_du_handicap' href='#link'><span>Plus d'infos</span></button>
                  <div className='clear' />
                </div>
              </div>
              <FiledOfWorkPopup id='Droit_du_handicap' title='Droit du handicap' />
            </div>

            <div className='col-12 col-md-6 col-lg-4 m-15px-tb'>
              <div className='feature-box'>
                <div className='icon'>
                  <GiInjustice />
                </div>
                <div className='feature-content'>
                  <h5>Droit pénal & Attentats</h5>
                  <p className='feature-content-desc'>Défense des victimes d'infractions</p>
                </div>
                <div className='feature-link'>
                  <button className='m-btn' type='button' data-toggle='modal' data-target='#Droit_penal_et_attentats' href='#link'><span>Plus d'infos</span></button>
                  <div className='clear' />
                </div>
              </div>
            </div>
            <FiledOfWorkPopup id='Droit_penal_et_attentats' title='Droit pénal & Attentats' />
          </div>
        </div>
      </section>
    )
  }
}

export default FieldsOfWork
