import React, { Component } from 'react'
import { TiSocialFlickr } from 'react-icons/ti'
import { RiMapPinLine } from 'react-icons/ri'
import { HiOutlineMailOpen } from 'react-icons/hi'
import ContactImg from '../assets/img/contact.png'
import { BiPhoneCall } from 'react-icons/bi'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import SvgPinLine from '../assets/img/map-pin-line.svg'

var LeafIcon = L.Icon.extend({
  options: {
    shadowUrl: shadowUrl,
    iconSize: [30, 36],
    shadowSize: [41, 41],
    iconAnchor: [15, 36],
    shadowAnchor: [15, 41],
    className: 'colorFilter'
  }
})
var LeafIcon2 = L.Icon.extend({
  options: {
    shadowUrl: shadowUrl,
    iconSize: [30, 36],
    shadowSize: [41, 41],
    iconAnchor: [15, 36],
    shadowAnchor: [15, 41],
    className: 'color2Filter'
  }
})

class Contact extends Component {
  render () {
    return (
      <section id='Contact' className='section gray-bg border-bottom'>
        <div className='container'>
          <div className='row justify-content-center text-center m-45px-b md-m-25px-b'>
            <div className='col-md-8 col-lg-6'>
              <div className='section-title'>
                <h6>Contact</h6>
                <div className='btm-style'>
                  <span><TiSocialFlickr /></span>
                </div>
                <p>Maître Laura WITZ vous accueille du lundi au vendredi sur rendez-vous.</p>
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-center mb-5'>
            <div className='col-xl-5 col-lg-6'>
              <div className='about-img-01'>
                <img src={ContactImg} title='' alt='' />
              </div>
            </div>
            <div className='col-xl-5 col-lg-6'>
              <div className='about-box about-box-01'>
                <h3>Laura WITZ</h3>
                <h6>Avocat en droit de la santé au Barreau d'Ajaccio</h6>
                <p>
                  <RiMapPinLine className='color map-icon' />
                  <strong> Cabinet principal : </strong>
                  1, rue Jérôme Péri - 20000 Ajaccio
                </p>
                <p>
                  <RiMapPinLine className='color2 map-icon' />
                  <strong> Cabinet secondaire : </strong>
                    33, via San Martinu - 20146 Sotta
                </p>
                <p>
                  <BiPhoneCall className='color map-icon' />
                  <strong> Tél :</strong> 04.13.96.17.61
                </p>
                <p>
                  <HiOutlineMailOpen className='color map-icon' />
                  <strong> Email : </strong> contact@witz-avocat.fr
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='situation'>
          <MapContainer center={[42.522724, 7.357631]} zoom={6} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy;'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker icon={new LeafIcon({ iconUrl: SvgPinLine })} position={[43.291090, 5.376050]} />
            <Marker icon={new LeafIcon2({ iconUrl: SvgPinLine })} position={[41.547110, 9.206630]} />
          </MapContainer>
        </div>
      </section>
    )
  }
}

export default Contact
