
import React, { Component } from 'react'

class FiledOfWorkPopup extends Component {

  constructor () {
    super()
    this.items = []
    this.items.Droit_medical = [
      <div>
        <p className='text-justify'>Avocat compétent en matière de droit médical, Maître Laura WITZ œuvre pour faire reconnaître vos droits à la suite d'une faute médicale, d'un aléa thérapeutique, ou d'une infection nosocomiale.</p>
        <h6><strong>La responsabilité médicale pour faute</strong></h6>
        <p className='text-justify'>Les victimes d'erreurs médicales peuvent obtenir la reconnaissance de la faute commise et la réparation de leurs préjudices devant les juridictions civiles, administratives mais aussi devant les juridictions ordinales.</p>
        <p className='text-justify'>Parce qu'il existe différents types de fautes et que celles-ci sont appréciées en fonction de la littérature médicale et de la situation concrète de la victime, Maître Laura WITZ fait appel à des médecins-conseils, kinésithérapeutes et ergothérapeutes pour assister ses clients lors des expertises médicales afin de défendre au mieux leurs intérêts.</p>
        <h6><strong>L'aléa thérapeutique</strong></h6>
        <p className='text-justify'>La loi du 4 mars 2002 a instauré un dispositif permettant aux victimes d’accidents médicaux ou à leurs ayants droit, d'obtenir une indemnisation, à la condition que l'accident soit survenu après le 4 septembre 2001 et qu'il respecte les conditions de gravité prévus par le Code de la santé public.</p>
        <p className='text-justify'>En cas d’aléa thérapeutique ouvrant droit à réparation, la commission de conciliation et d'indemnisation compétente (CCI) diligentera une expertise gratuite pour évaluer les préjudices subis et transmettra un avis à l’Office national d’indemnisation des accidents médicaux (ONIAM) débiteur de l’indemnisation.</p>
        <h6><strong>Les infections nosocomiales</strong></h6>
        <p className='text-justify'>Une infection est qualifiée de nosocomiale lorsqu'elle contractée à l'occasion d'un séjour dans un établissement de santé.</p>
        <p className='text-justify'>Les conséquences d'une infection nosocomiale ouvrent droit à une indemnisation de plein droit par l'ONIAM ou l'établissement de soins selon le taux de gravité définit par le code de la santé publique.</p>
      </div>
    ]

    this.items.Droit_pharmaceutique = [
      <div>
        <p className='text-justify'>Une affection iatrogène est une maladie, un état, ou un effet secondaire occasionné par un traitement médical.</p>
        <p className='text-justify'>Les conséquences d'une affection iatrogène ouvrent droit à une indemnisation de plein droit par l'ONIAM selon le taux de gravité définit par le code de la santé publique.</p>
        <p className='text-justify'>Néanmoins, la responsabilité des médecins prescripteurs, des pharmaciens, et des laboratoires pharmaceutiques peut aussi être engagée en cas de dommages subis à la suite de la prise d'un médicament.</p>
        <p className='text-justify'>Diplômée en la matière, Maître Laura WITZ vous assistera pour déterminer la responsabilité des différents intervenants.</p>
      </div>
    ]

    this.items.Produits_defecteux = [
      <div>
        <p className='text-justify'>Le Conseil des communautés européennes a adopté une directive le 25 juillet 1985 relative à la responsabilité du fait des produits défectueux. La transposition de cette directive a été effectuée en droit français par la loi du 19 mai 1988 qui a introduit certaines dispositions dans le code civil.</p>
        <p className='text-justify'>En matière de produits défectueux, le producteur engage sa responsabilité lorsqu'il délivre un produit qui n'offre pas la sécurité à laquelle l'utilisateur peut légitimement s'attendre.</p>
        <p className='text-justify'>Ainsi, les victimes, qu'elles soient ou non liées par contrat avec le producteur (ce qui englobe les professionnels et les consommateurs), peuvent demander réparation de leurs préjudices.</p>
        <p className='text-justify'>Forte de son expérience après le scandale des prothèses PIP, Maître Laura WITZ vous assistera pour démontrer le caractère défectueux du produit utilisé ou implanté.</p>
      </div>
    ]

    this.items.Mentions_Legales = [
      <div>
        <p>Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir ses pages. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a> sont:</p>
        <h6>Editeur du Site: </h6>
        <p>
          <strong>Laura WITZ</strong><br />
          Numéro de SIRET: 835 287 905 00011<br />
          Responsable editorial: Laura WITZ<br />
          1, rue Jérôme Péri - 20000 Ajaccio<br />
          Téléphone:0413961761<br />
          Email: contact@witz-avocat.fr<br />
          Site Web: <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a>
        </p>
        <h6>Hébergement:</h6>
        <p>
          Hébergeur: OVH<br />
          2 rue Kellermann - 59100 Roubaix - France<br />
          Site Web:  <a href='http://https://www.ovh.com'>https://www.ovh.com</a>
        </p>
        <h6>Développement: </h6>
        <p>
          <strong>Laura WITZ</strong><br />
          Adresse: 1, rue Jérôme Péri - 20000 Ajaccio<br />
          Site Web: <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a>
        </p>
        <h6>Conditions d’utilisation: </h6>
        <p>
          Ce site (<a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a>) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc…<br />
          <span>Laura WITZ</span> met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès de , et signaler toutes modifications du site qu'il jugerait utile. n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.
        </p>
        <h6>Cookies</h6>
        <p>Le site <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a> peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookies est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations . Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.</p>
        <h6>Liens hypertextes:</h6>
        <p>
          Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. <span>Laura WITZ</span> ne dispose d'aucun moyen pour contrôler les sites en connexion avec ses sites internet. ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'internaute, qui doit se conformer à leurs conditions d'utilisation.<br />
          Les utilisateurs, les abonnés et les visiteurs des sites internet de ne peuvent mettre en place un hyperlien en direction de ce site sans l'autorisation expresse et préalable de <span>Laura WITZ</span>.<br />
          Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un des sites internet de <span>Laura WITZ</span>, il lui appartiendra d'adresser un email accessible sur le site afin de formuler sa demande de mise en place d'un hyperlien. <span>Laura WITZ</span> se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.
        </p>
        <h6>Services fournis: </h6>
        <p>
          L'ensemble des activités de la société ainsi que ses informations sont présentés sur notre site <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a>.<br />
          <span>Laura WITZ</span> s’efforce de fournir sur le site www.witz-avocat.fr des informations aussi précises que possible. les renseignements figurant sur le site <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a> ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site www.witz-avocat.fr sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.
        </p>
        <h6>Limitation contractuelles sur les données: </h6>
        <p>
          Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse contact@witz-avocat.fr, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).<br />
          Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de données consécutives au téléchargement. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour<br />
          Les liens hypertextes mis en place dans le cadre du présent site internet en direction d'autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de <span>Laura WITZ</span>.
        </p>
        <h6>Propriété intellectuelle:</h6>
        <p>
          Tout le contenu du présent sur le site <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a>, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.<br />
          Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de <span>Laura WITZ</span>. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.
        </p>
        <h6>Déclaration à la CNIL: </h6>
        <p>Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique, aux fichiers et aux libertés, ce site n'a pas fait l'objet d'une déclaration  auprès de la Commission nationale de l'informatique et des libertés (<a href='http://www.cnil.fr/'>www.cnil.fr</a>).</p>
        <h6>Litiges: </h6>
        <p>Les présentes conditions du site <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a> sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>
        <h6>Données personnelles:</h6>
        <p>
          De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet <a href='http://www.witz-avocat.fr'>www.witz-avocat.fr</a>.
          Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que: votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information.
          Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site Internet, notamment: des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d'accès. De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
        </p>
      </div>
    ]

    this.items.Accidents = [
      <div>
        <p className='text-justify'>En matière d’indemnisation des victimes d'accidents qu'il s'agisse d'un accident de la circulation, d'un accident du travail, d'un accident domestique ou de sport, Maître Laura WITZ conseille et assiste les victimes dans le processus indemnitaire amiable ou judiciaire afin d'obtenir la plus juste indemnisation.</p>
        <h6><strong>Accidents de la circulation</strong></h6>
        <p className='text-justify'>Toute victime d’un accident de la route en France ou à l'étranger, qu’elle soit conducteur, passager, cycliste ou piéton, doit pouvoir être indemnisée, des dommages corporels subis.</p>
        <h6><strong>Accidents du travail</strong></h6>
        <p className='text-justify'>L’accident du travail est un accident survenu  à l’occasion de l’exercice de votre contrat de travail pour le compte d’un ou plusieurs employeurs.</p>
        <p className='text-justify'>Dès que le caractère professionnel de l’accident est reconnu, le salarié bénéficie d’une réparation automatique et forfaitaire.</p>
        <p className='text-justify'>Néanmoins, dans certaines hypothèses le salarié peut bénéficier d’une indemnisation complémentaire, notamment en cas de faute inexcusable commise par l'employeur permettant d'obtenir une majoration de la rente accident du travail et une indemnisation complémentaire au titre des différents préjudices subis et non réparés par la majoration de la rente.</p>
        <h6><strong>Accidents de la vie courante</strong></h6>
        <p className='text-justify'>Appelés aussi accidents domestiques, ces accidents de la vie courante sont pris en charge par des contrats d’assurance spécifiques 'Garantie Accident de la Vie'.</p>
        <p className='text-justify'>Maître Laura WITZ vous aidera dans l'analyse des clauses contractuelles afin que l'indemnisation qui pourra être perçue soit la meilleure possible.</p>
        <h6><strong>Accidents de sport</strong></h6>
        <p className='text-justify'>Un accident de sport peut relever de la vie privée, de la vie professionnelle ou d’une activité associative.</p>
        <p className='text-justify'>En cas de blessures au cours de la pratique d’une activité sportive vous pouvez être indemnisé de vos différents préjudices.</p>
        <p className='text-justify'>Maître Laura WITZ vous aidera à démontrer la faute personnelle ou de jeu ayant provoqué l’accident sportif.</p>
      </div>
    ]

    this.items.Droit_penal_et_attentats = [
      <div>
        <p className='text-justify'>Victime d'une infraction pénale ou d'un attentat, Maître Laura WITZ vous accompagne tout au long de la procédure pénale et indemnitaire devant les différentes juridictions (Tribunal correctionnel, Cour d'Assises, Commission d'indemnisation des victimes d'infractions et Fonds de garantie des victimes d'actes de terrorisme et autres infractions).</p>
        <p className='text-justify'>Ayant œuvré pour les victimes des attentats qui ont frappé Paris en 2015 et participé à l'élaboration du Livre Blanc sur les Préjudices spécifiques de ces victimes aux côtés de Confrères parisiens, Maître Laura WITZ est sensible aux particularités des dommages physiques et psychiques subis par ces témoins de scènes de guerre et leurs familles.</p>
      </div>
    ]

    this.items.Droit_du_handicap = [
      <div>
        <p className='text-justify'>Du fait de la multiplicité des administrations concernées et des droits existants, il est souvent difficile pour les personnes en situation de handicap d’obtenir les prestations qui leur sont dues.</p>
        <p className='text-justify'>Qu’il s’agisse d’une demande de droit ou de la contestation d’un refus de prestations, Maître Laura WITZ vous assistera pour exercer les recours amiables et juridictionnels appropriés devant le pôle social du tribunal Judiciaire et le Tribunal Administratif.</p>
        <p className='text-justify'>Ayant à cœur d’aider les personnes en situation de handicap, Maître Laura WITZ œuvre en qualité de membre honoraire au sein de l’association « les Patients Experts » aux côtés de son président Mr Jérôme DEFAZIO.</p>
      </div>
    ]
  }

  render () {
    return (
      <div className='modal fade bd-example-modal-lg' id={this.props.id} tabIndex='-1' role='dialog' aria-labelledby={this.props.id} aria-hidden='true'>
        <div className='modal-dialog modal-lg modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{this.props.title}</h5>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {this.items[this.props.id]}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FiledOfWorkPopup
