import React, { Component } from 'react'
import { TiSocialFlickr } from 'react-icons/ti'
import PresentationImg from '../assets/img/presentation.jpg'
import Signature from '../assets/img/sign.png'

class Presentation extends Component {
  render () {
    return (
      <section id='Presentation' className='section gray-bg border-bottom'>
        <div className='container'>
          <div className='row justify-content-center text-center m-45px-b md-m-25px-b'>
            <div className='col-md-6 col-lg-6'>
              <div className='section-title'>
                <h6>Présentation</h6>
                <div className='btm-style'>
                  <span><TiSocialFlickr /></span>
                </div>
                <p><i>« La résilience, c'est plus que résister, c'est aussi apprendre à vivre »</i><br />Boris Cyrulnik
                </p>
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-center mb-5'>
            <div className='col-xl-7 col-lg-6'>
              <div className='about-box about-box-02'>
                <h4 className='text-uppercase'>M<span className='eSpe'>e</span> LAURA WITZ</h4>
                <p className='text-justify mt-3'>Le cabinet de Maître Laura WITZ, avocat aux Barreaux de Marseille et d'Ajaccio intervenant sur l'ensemble du territoire, est dédié au droit de la santé.</p>
                <p className='text-justify'>Diplômée en droit médical, droit pharmaceutique et réparation du dommage corporel, Maître Laura WITZ s’entoure de professionnels compétents tels que médecins-conseils, experts comptables et ergothérapeutes spécialisés dans l’analyse des dommages et besoins des victimes pour obtenir la meilleure indemnisation possible dans le cadre de négociations amiables ou de procédures judicaires devant les juridictions, commissions de conciliation et d'indemnisation et fonds de garantie.</p>
                <p className='text-justify'>Maître Laura WITZ vous assiste à tous les stades de la procédure et vous accompagne à l’expertise médicale, pierre angulaire du processus indemnitaire.</p>
                <p className='text-right mt-4 mr-3 mb-0'><img src={Signature} alt='Laura WITZ' /></p>
              </div>
            </div>
            <div className='order-first1 col-xl-5 col-lg-6'>
              <div className='about-img-02'>
                <img src={PresentationImg} title='' alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Presentation
