import React from 'react'
import './assets/scss/App.scss'

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import FieldsOfWork from './pages/FieldsOfWork'
import Presentation from './pages/Presentation'
import Honorary from './pages/Honorary'
import Contact from './pages/Contact'
import Logo from './assets/img/logo192.png'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import './assets/js/functions.js'

function App () {
  return (
    <div className='App'>
      <div id='loading'>
        <div className='load-content'>
          <div className='load-content-block'>
            <img src={Logo} alt='Laura WITZ Avocat' />
            <h1>Me Laura WITZ</h1>
            <h6>Avocat en droit de la santé au Barreau d'Ajaccio</h6>
            <div class='load-circle'>
              <span class='one' />
            </div>
          </div>
        </div>
      </div>
      <Header />
      <main>
        <Home />
        <Presentation />
        <FieldsOfWork />
        <Honorary />
        <Contact />
        <Footer />
      </main>
    </div>
  )
}

export default App
