import React, { Component } from 'react'
import { TiSocialFlickr } from 'react-icons/ti'

class Honorary extends Component {
  render () {
    return (
      <section id='Honoraires' className='section p-0px-b gray-bg'>
        <div className='container'>
          <div className='row justify-content-center text-center m-45px-b md-m-25px-b'>
            <div className='col-md-8 col-lg-6'>
              <div className='section-title'>
                <h6>Honoraires</h6>
                <div className='btm-style'>
                  <span><TiSocialFlickr /></span>
                </div>
              </div>
            </div>
          </div>
          <div className='row align-items-center hon'>
            <div className='col-md-12 md-m-45px-b'>
              <div className='skill-title'>
                <p className='p-0px-b md-p-15px-b text-justify'>Le règlement intérieur national régissant la profession d'Avocat prévoit que la rémunération de l’avocat est fonction, notamment, de chacun des éléments suivants conformément aux usages :</p>
                <ul>
                  <li>Le temps consacré à l’affaire,</li>
                  <li>Le travail de recherche,</li>
                  <li>La nature et la difficulté de l’affaire,</li>
                  <li>L’importance des intérêts en cause,</li>
                  <li>L’incidence des frais et charges du cabinet auquel il appartient,</li>
                  <li>Sa notoriété, ses titres, son ancienneté, son expérience et la spécialisation dont il est titulaire,</li>
                  <li>Les avantages et le résultat obtenus au profit du client par son travail, ainsi que le service rendu à celui-ci,</li>
                  <li>La situation de fortune du client. </li>
                </ul>
                <p className='p-0px-b md-p-15px-b text-justify'>En dehors des dossiers de défense pénale et des contentieux pour lesquels Maître Laura WITZ accepte l'aide juridictionnelle, les honoraires seront de deux types : un honoraire forfaitaire et un honoraire de résultat à chaque versement provisionnel par l'assureur chargé de l'indemnisation.</p>
                <p className='p-0px-b md-p-15px-b text-justify'>Aucune avance n'est réclamée à la victime.</p>
                <p className='p-0px-b md-p-15px-b text-justify'>Il est primordial de demander aux clients s'ils bénéficient d'une assurance de protection juridique. Très souvent rattachée aux contrats d'assurance habitation ou responsabilité civile, cette couverture permet la prise en charge de certains frais inhérents à toute procédure comme les frais d'expertise, d'assistance par un médecin-conseil, certains honoraires de l'avocat...</p>
                <p className='p-0px-b md-p-15px-b text-justify'>Consciente des situations particulières des victimes de dommages corporels dont l'état engendre parfois certaines difficultés financières, Maître Laura WITZ ne manquera pas de s'enquérir des garanties dont ses clients bénéficient afin de faire supporter les frais de procédure aux assurances préalablement souscrites par les clients.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Honorary
