import $ from 'jquery'
var scrollObject = {}
window.onscroll = getScrollPosition
function getScrollPosition () {
  scrollObject = {
    x: window.pageXOffset,
    y: window.pageYOffset
  }
  if (scrollObject.y > 200) {
    document.getElementById('nav').classList.add('fixed-header')
    document.getElementById('logo').classList.add('hide')
    document.getElementById('logob').classList.remove('hide')
  } else {
    document.getElementById('nav').classList.remove('fixed-header')
    document.getElementById('logo').classList.remove('hide')
    document.getElementById('logob').classList.add('hide')
  }
}

$(function () {
  document.getElementById('loading').style.display = 'none'
  $(document).on('scroll', onScroll)
  var deviceAgent = navigator.userAgent.toLowerCase();

  if (!deviceAgent.match(/(iphone|ipod|ipad)/)) {
    simpleParallax('home', 3)
  }
  $('.navbar-toggler').on('click', function (a) {
    a.preventDefault()
    $('.navbar').addClass('fixed-header')
    document.getElementById('logo').classList.add('hide')
    document.getElementById('logob').classList.remove('hide')
  })
  $('.navbar-nav .nav-link').on('click', function () {
    var toggle = $('.navbar-toggler').is(':visible')
    if (toggle) {
      $('.navbar-collapse').collapse('hide')
    }
  })
})

function onScroll (event) {
  var scrollPos = $(document).scrollTop() + 1
  $('.navbar-nav a').each(function () {
    var currLink = $(this)
    var refElement = $(currLink.attr('href'))
    if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
      $('.navbar-nav li a').removeClass('active')
      currLink.addClass('active')
    } else {
      currLink.removeClass('active')
    }
  })
}

const simpleParallax = (elem, modifier) => {
  let paras = document.getElementsByClassName(elem);
  for (let i = 0; i < paras.length; i++) {
    let para = paras[i];
    para.setAttribute('style', 'background-repeat: no-repeat; background-attachment: fixed; background-size: cover;background-position: center center;');
    const sp = () => {
      let x = para.getBoundingClientRect().top / modifier
      let y = Math.round(x * 100) / 100
      para.style.backgroundPosition = 'center ' + y + 'px'
      requestAnimationFrame(sp)
    }
    requestAnimationFrame(sp)
  }
}
